import React, { Component } from "react"
import { Button, Dropdown, DropdownDivider, DropdownHeader, DropdownItem, DropdownMenu, Grid, Header, HeaderContent, HeaderSubheader, Icon, Image, Menu, MenuItem, Message, Segment } from "semantic-ui-react"
import { Timetable, TimetableComponent } from "./Timetable"

export default class App extends Component {

	CACHE_TABLE = "timetable"
	CACHE_KEY_TIMETABLE_DATE = "date"
	CACHE_KEY_TIMETABLE_VALUE = "value"
	DATE_FORMAT_DATE_SELECTOR = { year: "numeric", month: "short", day: "numeric" }
	TIMETABLE_ENDPOINT = "https://s3-ap-southeast-1.amazonaws.com/open-ws/weektimetable"
	TIME_BEGIN = "08:30 AM"
	TIME_END = "06:30 PM"
	today

	constructor(props) {
		super(props)
		const date = new Date()
		date.setUTCHours(date.getUTCHours() + 8);
		date.setUTCHours(0, 0, 0, 0);
		this.today = date
		const filters = window.localStorage.getItem("filters")
		const theme = window.localStorage.getItem("theme")
		const menu = window.localStorage.getItem("menu")
		this.state = {
			selected_day: this.today,
			filters: filters !== null ? JSON.parse(filters) : [],
			timetable: null,
			timetable_key: 0,
			dark_mode: theme === "dark",
			hide_desc: window.localStorage.getItem("hide_desc") !== null,
			menu: menu !== null ? menu : "timetable"
		}
	}

	preprocessTimetable(timetable) {
		const result = []
		for (const index in timetable) {
			const entry = timetable[index]
			entry["TIME_FROM_TS"] = new Date(entry["TIME_FROM_ISO"]).getTime()
			entry["TIME_TO_TS"] = new Date(entry["TIME_TO_ISO"]).getTime()

			const room = entry["ROOM"]
			let room_level = null
			let room_level_idx
			if ((room_level_idx = room.lastIndexOf(" @ Level ")) !== -1) {
				room_level = parseInt(room.slice(room_level_idx + " @ Level ".length))
			} else if ((room_level_idx = room.lastIndexOf("-")) !== -1) {
				room_level = []
				for (let i = room_level_idx - 1; i >= 0; i--) {
					if (!isNaN(room.charAt(i))) {
						room_level.push(room.charAt(i))
					}
				}
				if (room_level.length > 0) {
					room_level = parseInt(room_level.reverse().join(""))
					if (room_level === 0 || isNaN(room_level)) {
						room_level = null
					}
				} else {
					room_level = null
				}
			}

			let room_block = null
			let room_block_idx = room.indexOf("-")
			if (room_block_idx === 1) {
				room_block = room.charAt(0)
			} else if (room.startsWith("Tech Lab")) {
				room_block = "E"
			}

			entry["ROOM_BLOCK"] = room_block
			entry["ROOM_LEVEL"] = room_level !== null ? parseInt(room_level) : null
			result.push(entry)
		}
		return result.sort((x, y) => x["TIME_FROM_TS"] - y["TIME_FROM_TS"])
	}

	async donwloadTimetable() {
		const cache = await caches.open(this.CACHE_TABLE)
		const cachedResponse = await cache.match(this.CACHE_KEY_TIMETABLE_VALUE)
		if (cachedResponse) {
			const date = new Date(await (await cache.match(this.CACHE_KEY_TIMETABLE_DATE)).text())
			setTimeout(async () => {
				const response = await fetch(this.TIMETABLE_ENDPOINT, { method: "HEAD" })
				for (const header_name of ["content-length", "last-modified"]) {
					const expected = response.headers.get(header_name)
					const actual = cachedResponse.headers.get(header_name)
					if (expected !== actual) {
						await caches.delete(this.CACHE_TABLE)
						window.location.reload()
						break
					}
				}
			})
			return new Timetable(await cachedResponse.json(), date)
		}

		const response = await fetch(this.TIMETABLE_ENDPOINT, { method: "GET" })
		const timetable = this.preprocessTimetable(await response.json())
		const date = new Date()
		await cache.put(this.CACHE_KEY_TIMETABLE_DATE, new Response(date.toISOString()))
		await cache.put(this.CACHE_KEY_TIMETABLE_VALUE, new Response(JSON.stringify(timetable), { headers: response.headers }))
		return new Timetable(timetable, date)
	}

	toTimetableDatestamp(date) {
		const day = date.getDate().toString().padStart(2, '0')
		const month = date.toLocaleString('en-US', { month: 'short' }).toUpperCase()
		const year = date.getFullYear().toString().slice(-2)
		return `${day}-${month}-${year}`
	}

	parseFilters() {
		const filter_room_blocks = new Set()
		const filter_room_levels = new Set()
		for (const [filter_type, filter_value] of this.state.filters.map(e => e.split("_", 2))) {
			if (filter_type === "block") {
				filter_room_blocks.add(filter_value)
			} else if (filter_type === "level") {
				filter_room_levels.add(parseInt(filter_value))
			}
		}
		return [
			filter_room_blocks.size > 0 ? filter_room_blocks : null,
			filter_room_levels.size > 0 ? filter_room_levels : null
		]
	}

	async componentDidMount() {
		this.setState({ timetable: await this.donwloadTimetable(), timetable_key: this.state.timetable_key + 1 })
	}

	renderInfo() {
		return <>
			<Message
				inverted={this.state.dark_mode}
				header="What is this website for?"
				content="Searching for a free classroom on APU campus has become a lot easier! This site is your go-to tool for finding free classrooms.
						Whether you need a quiet space for group study, a place to relax, or simply an air-conditioned room to escape the heat,
						this site will provide you up-to-date schedules of available classrooms."
			/>
			<Message
				inverted={this.state.dark_mode}
				header="How it works?"
				content={<>
					The complete APU weekly timetable is available <a href="https://s3-ap-southeast-1.amazonaws.com/open-ws/weektimetable">here</a>. This website
					downloads the timetable on your device from this API endpoint and displays free room schedules accordingly. If the timetable is outdated, use
					the green button under the '<Icon name="calendar alternate" />Timetable' tab to re-download the timetable.
				</>}
			/>
			<Message
				inverted={this.state.dark_mode}
				header="What's the '.pages.dev' for in your URL?"
				content={<>
					<a href="https://pages.dev">pages.dev</a> is a service provided by Cloudflare that lets anyone host their websites on the cloud for free.
					This website is hosted on Cloudflare Pages, hence their sub-domain.
				</>}
			/>
		</>
	}

	buildFilterOptions() {
		const availableBlockFilters = []
		for (const room_block of this.state.timetable.room_blocks) {
			if (room_block !== null) {
				const value = ["block", room_block].join("_")
				availableBlockFilters.push({ key: value, text: `Block ${room_block}`, value: value, icon: "building" })
			}
		}
		const availableLevelFilters = []
		for (const room_level of this.state.timetable.room_levels) {
			if (room_level !== null) {
				const value = ["level", room_level].join("_")
				availableLevelFilters.push({ key: value, text: `Level ${room_level}`, value: value, icon: "numbered list" })
			}
		}

		const result = []
		result.push(<DropdownHeader key="header-building" icon="building" content="Filter by block"/>)
		for(const entry of availableBlockFilters){
			result.push(entry)
		}
		result.push(<DropdownDivider key="divider-1"/>)
		result.push(<DropdownHeader key="header-level" icon="numbered list" content="Filter by level"/>)
		for(const entry of availableLevelFilters){
			result.push(entry)
		}
		return result
	}

	buildDaysOption(){
		const result = []
		for(const entry of this.state.timetable.days){
			const is_today = entry.getTime() === this.today.getTime()
			result.push({key: entry.getTime(), value: entry.getTime(), text: entry.toLocaleDateString("en-US", this.DATE_FORMAT_DATE_SELECTOR), icon: "calendar" + (is_today ? " green" : "")})
		}
		return result
	}

	renderTimetable() {
		return <>
			<Segment inverted={this.state.dark_mode}>
				<Grid stackable divided="vertically" inverted={this.state.dark_mode}>
					<Grid.Row>
						<Grid.Column width={13} stackable>
							<Dropdown
								inverted={this.state.dark_mode}
								disabled={this.state.timetable === null}
								fluid
								selection
								options={this.state.timetable !== null ? this.buildDaysOption() : [{ key: this.state.selected_day.getTime(), value: this.state.selected_day.getTime(), text: "Loading..." }]}
								defaultValue={this.state.selected_day.getTime()}
								onChange={(e, { value }) => {
									if (new Date(value).getTime() !== this.state.selected_day.getTime()) {
										this.setState({ selected_day: new Date(value), timetable_key: this.state.timetable_key + 1 })
									}
								}}
							/>
						</Grid.Column>
						<Grid.Column width={3}>
							<Button.Group fluid inverted={this.state.dark_mode}>
								<Button color="blue" icon="refresh" title="Refresh timetable" onClick={async e => {
									e.target.classList.add("disabled")
									e.target.classList.add("loading")
									try {
										await new Promise(resolve => setTimeout(resolve, 100))
										this.setState({ timetable_key: this.state.timetable_key + 1 })
									} finally {
										e.target.classList.remove("disabled")
										e.target.classList.remove("loading")
									}
								}} />
								<Button
									color="green"
									icon="server"
									title="Redownload timetable"
									onClick={async e => {
										e.target.classList.add("disabled")
										try {
											await caches.delete(this.CACHE_TABLE)
											this.setState({
												timetable: await this.donwloadTimetable(),
												timetable_key: this.state.timetable_key + 1
											})
										} finally {
											e.target.classList.remove("disabled")
										}
									}}
								/>
							</Button.Group>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width={13} stackable>
							<Dropdown
								inverted={this.state.dark_mode}
								key={this.state.filters.join(";")}
								disabled={this.state.timetable === null}
								placeholder="Filters"
								fluid
								multiple
								search
								selection
								defaultValue={this.state.filters}
								options={this.state.timetable !== null ? this.buildFilterOptions() : []}
								onChange={(e, { value }) => {
									console.log(value)
									const filters = [...new Set(value)]
									window.localStorage.setItem("filters", JSON.stringify(filters))
									this.setState({ filters: filters, timetable_key: this.state.timetable_key + 1 })
								}}
							>
							</Dropdown>
						</Grid.Column>
						<Grid.Column width={3}>
							<Button
								fluid
								color="purple"
								icon="trash"
								title="Clear Filters"
								onClick={_ => {
									window.localStorage.removeItem("filters")
									this.setState({ filters: [], timetable_key: this.state.timetable_key + 1 })
								}}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Segment>
			<TimetableComponent
				key={this.state.timetable_key}
				inverted={this.state.dark_mode}
				timetable={this.state.timetable !== null ? this.state.timetable : new Timetable([], new Date(0))}
				day={this.state.selected_day}
				time_begin={this.TIME_BEGIN}
				time_end={this.TIME_END}
				filters={this.parseFilters()}
			/>
			<span style={{ fontStyle: "italic" }}>{this.state.timetable !== null ? `Timetable was last-downloaded on ${this.state.timetable.download_date}` : "Retrieving timetable..."}</span>
		</>
	}

	render() {
		const menuHandler = (e, { name: menu }) => {
			window.localStorage.setItem("menu", menu)
			this.setState({ menu: menu })
		}
		let menuContents
		if (this.state.menu === "timetable") {
			menuContents = this.renderTimetable()
		} else if (this.state.menu === "info") {
			menuContents = this.renderInfo()
		} else {
			window.localStorage.removeItem("menu")
			this.setState({ menu: "info" })
			menuContents = "..."
		}
		return <Grid centered container inverted={this.state.dark_mode}>
			<Grid.Column textAlign="center" width={15} />
			<Header as="h2" textAlign="left" inverted={this.state.dark_mode}>
				<Image circular src="/logo.png" />
				<HeaderContent>
					APU Classroom Finder
					<HeaderSubheader>Locate empty classrooms with ease!</HeaderSubheader>
				</HeaderContent>
			</Header>
			<Grid.Column width={16}>
				<Menu pointing secondary fluid inverted={this.state.dark_mode}>
					<MenuItem
						name="info"
						icon="info"
						active={this.state.menu === "info"}
						onClick={menuHandler}
					/>
					<MenuItem
						name="timetable"
						icon="calendar alternate"
						active={this.state.menu === "timetable"}
						onClick={menuHandler}
					/>
				</Menu>
				{menuContents}
			</Grid.Column>
		</Grid>
	}
};