import { Component } from "react"
import { Placeholder, PlaceholderLine, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from "semantic-ui-react"

function strtotimeMalaysia(timeString, relativeDate) {
    const [time, period] = timeString.split(" ")
    let [hours, minutes] = time.split(":").map(Number)

    if (period === "PM" && hours !== 12) {
        hours += 12
    } else if (period === "AM" && hours === 12) {
        hours = 0
    }

    const date = new Date(relativeDate)
    date.setUTCHours(hours - 8, minutes, 0, 0)
    return date.getTime()
}

function calculateAvailability(ts_begin, ts_end, ts_now) {
    if (ts_now < ts_begin) {
        return Math.floor(-(ts_begin - ts_now) / 1000)
    }
    if (ts_now < ts_end) {
        return Math.floor((ts_end - ts_now) / 1000)
    }
    return 0
}

function *checkRoomAvailability(timetable, date, time_begin, time_end, filter_room_blocks, filter_room_levels) {
    const selected_date = date.toISOString().split("T")[0]
    const _settime = new URLSearchParams(window.location.search).get("settime")
    const ts_now = new Date(_settime !== null ? _settime : new Date()).getTime()

    const booked_rooms = {}
    for(const room of timetable.rooms){
        if(filter_room_blocks !== null && !filter_room_blocks.has(timetable.room_mappings[room]["block"])){
            continue
        }
        if(filter_room_levels !== null && !filter_room_levels.has(timetable.room_mappings[room]["level"])){
            continue
        }
        booked_rooms[room] = []
    }

    for (const index in timetable.data) {
        const entry = timetable.data[index]
        if(!(entry["ROOM"] in booked_rooms)){
            continue
        }
        if (entry["DATESTAMP_ISO"] === selected_date) {
            booked_rooms[entry["ROOM"]].push(entry)
        }
    }

    const time_begin_ts = strtotimeMalaysia(time_begin, date)
    const time_end_ts = strtotimeMalaysia(time_end, date)
    for(const [room, booked] of Object.entries(booked_rooms)){
        if (booked.length === 0) {
            yield [
                room,
                time_begin,
                time_end,
                calculateAvailability(time_begin_ts, time_end_ts, ts_now),
                Math.floor((time_end_ts - time_begin_ts) / 1000)
            ]
            continue
        }

        for (let i = 0; i <= booked.length; i++) {
            let /*time_from1, */time_to1, time_from2/*, time_to2*/
            if (i === 0) {
                //time_from1 = time_begin
                time_to1 = time_begin
                time_from2 = booked[i]["TIME_FROM"]
                //time_to2 = booked[i]["TIME_TO"]
            } else if (i === booked.length) {
                //time_from1 = booked[i - 1]["TIME_FROM"]
                time_to1 = booked[i - 1]["TIME_TO"]
                time_from2 = time_end
                //time_to2 = time_end
            } else {
                //time_from1 = booked[i - 1]["TIME_FROM"]
                time_to1 = booked[i - 1]["TIME_TO"]
                time_from2 = booked[i]["TIME_FROM"]
                //time_to2 = booked[i]["TIME_TO"]
            }
    
            const ts_begin = strtotimeMalaysia(time_to1, date)
            const ts_end = strtotimeMalaysia(time_from2, date)
            if (ts_end - ts_begin < 3600) {
                continue
            }
            if (ts_end > time_end_ts) {
                continue
            }
            yield [
                room,
                time_to1,
                time_from2,
                calculateAvailability(ts_begin, ts_end, ts_now),
                Math.floor((ts_end - ts_begin) / 1000)
            ]
        }
    }
}

function sortRoomAvailabilities(timetable, day, time_begin, time_end, filter_room_blocks, filter_room_levels){
    const availability = []
    for (const [room, from, to, ts_availability, ts_duration] of checkRoomAvailability(timetable, day, time_begin, time_end, filter_room_blocks, filter_room_levels)) {
        let score
        if (ts_availability > 0) {
            score = (2 ** 23) - ts_availability
        } else if (ts_availability < 0) {
            score = (2 ** 29) - ts_availability
        } else {
            score = (2 ** 31) + ts_duration
        }
        availability.push({ score: score, room: room, from: from, to: to, availability: ts_availability, duration: ts_duration })
    }
    return availability.sort((x, y) => x["score"] - y["score"])
}

function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const secs = seconds % 60

    const hoursDisplay = hours > 0 ? hours + "h " : ""
    const minutesDisplay = minutes > 0 ? minutes + "m " : ""
    const secondsDisplay = secs > 0 ? secs + "s" : ""

    return (hoursDisplay + minutesDisplay + secondsDisplay).trimEnd()
}

export class Timetable {

    constructor(data, download_date) {
        this.data = data
        this.download_date = download_date
        this.room_mappings = {}

        const days = new Set()
        const rooms = new Set()
        const room_blocks = new Set()
        const room_levels = new Set()
        for (const entry of data) {
            days.add(entry["DATESTAMP_ISO"])
            rooms.add(entry["ROOM"])
            room_blocks.add(entry["ROOM_BLOCK"])
            room_levels.add(entry["ROOM_LEVEL"])
            this.room_mappings[entry["ROOM"]] = {block: entry["ROOM_BLOCK"], level: entry["ROOM_LEVEL"]}
        }

        this.rooms = [...rooms]
        this.rooms.sort()

        this.room_blocks = [...room_blocks]
        this.room_blocks.sort()

        this.room_levels = [...room_levels]
        this.room_levels.sort(new Intl.Collator(undefined, {numeric: true, sensitivity: "base"}).compare)

        this.days = [...days]
        this.days.sort()
        this.days = this.days.map(day => new Date(day))
    }
};

export class TimetableComponent extends Component {

    constructor(props) {
        super(props)
        const [filter_room_blocks, filter_room_levels] = props.filters
        this.inverted = props.inverted
        this.state = {
            availability: sortRoomAvailabilities(props.timetable, props.day, props.time_begin, props.time_end, filter_room_blocks, filter_room_levels),
            has_timetable: props.timetable.download_date.getTime() > 0,
            time_end: props.time_end
        }
    }

    render() {
        return <Table celled compact inverted={this.inverted}>
            <TableHeader>
                <TableRow>
                    <TableHeaderCell width={8}>Room</TableHeaderCell>
                    <TableHeaderCell width={4}>Availability</TableHeaderCell>
                    <TableHeaderCell width={4}>Remaining Time</TableHeaderCell>
                </TableRow>
            </TableHeader>
            <TableBody>
                {this.state.has_timetable ? this.state.availability.map((entry, index) => {
                    let rowClass
                    if (entry["availability"] > 0) {
                        rowClass = "positive"
                    } else if (entry["availability"] < 0) {
                        rowClass = "warning"
                    } else {
                        rowClass = "negative disabled"
                    }
                    const until_end_of_day = entry["to"] === this.state.time_end
                    const remainingTime = entry["availability"] === 0 ? "No longer available" : (entry["availability"] > 0 ?
                        (until_end_of_day ? `Free for the day (>${formatTime(entry["availability"])})` : `Free for ${formatTime(entry["availability"])}`) :
                        `Free in ${formatTime(-entry["availability"])}`
                    )
                    return <TableRow key={index} className={rowClass}>
                        <TableCell data-label="Room"><b>{entry["room"]}</b></TableCell>
                        <TableCell data-label="Availability">{entry["from"]} - {until_end_of_day ? `end of day (≈${entry["to"]})` : entry["to"]}</TableCell>
                        <TableCell data-label="Remaining Time">{remainingTime}</TableCell>
                    </TableRow>
                }) : [...Array(50).keys()].map((_, index) => <TableRow key={index} >
                    <TableCell data-label="Room"><Placeholder><PlaceholderLine/></Placeholder></TableCell>
                    <TableCell data-label="Availability"><Placeholder><PlaceholderLine/></Placeholder></TableCell>
                    <TableCell data-label="Remaining Time"><Placeholder><PlaceholderLine/></Placeholder></TableCell>
                </TableRow>)}
            </TableBody>
        </Table>
    }
};